import { useLocales } from "../../locales";

const AttributeContentMap: { [key: string]: string } = {
    'displayname': 'Display Name',
    'givenname': 'First Name',
    'surname': 'Last Name',
    'email': 'Email',
    'mail': 'Email',
    'name': 'First Name',
    'e-mail': 'Email',
    'first name': 'First Name',
    'last name': 'Last Name',
};

export function getAttributeContent(attributeName: string): string {

    const { translate } = useLocales();
    let lowerCaseAttributeName = attributeName.toLowerCase().replace(/\s/g, '').replace(/\u2013|\u2014/g, '');
    let translated = translate(lowerCaseAttributeName);
    const translatedString = translated.toString();
    return translatedString;
}


